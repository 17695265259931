import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';
//TODO delete later 
//import heroImage from '../../../static/img/software-development-team.jpg'

import './index.css';

// Sections
import Hero from '../../components/TechnologyHero';
import HeroBody from '../../components/webMobileAppDevelopmentSections/HeroBody';
import EllipseQuoteSection from '../../components/TopOrangeEllipseQuote';
import WebMobileAppDevelopmentBullets from '../../components/webMobileAppDevelopmentSections/Bullets'
import WebMobileAppDevelopmentCustom from '../../components/webMobileAppDevelopmentSections/Custom'
import RecentWork from '../../components/homeSections/RecentWork';
import BestBlogs from '../../components/BestBlogs';
import FormSection from '../../components/Form';

const pageTitle =
  'Web & Mobile App Development | Devetry';
const pageDescription =
  'Build your next application with Devetry! We offer web and mobile app development across all languages and frameworks.';

const WebMobileAppDevelopmentPageTemplate = ({ recentWork, blogs, item, heroImage }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="salesforce-section root-container">
        <Hero
          title="Web & Mobile App Development"
          description="We build native, progressive and conventional apps for today's leading businesses."
          image={heroImage}
          body={<HeroBody />}
        />
        <EllipseQuoteSection
          title="Our App Arsenal"
          body={
            <span >
              <p className='bordered-quote'>
                Here are just some of the languages, frameworks, 
                and technologies we use to build custom web and mobile 
                applications. Don’t see yours listed? We probably do 
                that one too.
              </p>
              <br/>
              <br/>
              <WebMobileAppDevelopmentBullets/>
            </span>
          }
        />
        <WebMobileAppDevelopmentCustom item={item}/>
        <RecentWork title="Some Cool Apps We've Built" items={recentWork} />
        <BestBlogs items={blogs} title="Refine your strategic skills" />
        <FormSection />
      </main>
    </>
  )
}

const WebMobileAppDevelopmentPage = ({ data }) => {
  const recentWork = data.recentWork.edges.map((e) => ({
      ...e.node.frontmatter,
      slug: e.node.fields.slug
    }));

  const blogs = data.blogs.edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));

  const item = data.file;
  const heroImage = data.hero;

  return <WebMobileAppDevelopmentPageTemplate recentWork={ recentWork } blogs={ blogs } item={ item } heroImage={ heroImage } />;
};

WebMobileAppDevelopmentPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default WebMobileAppDevelopmentPage;


export const pageQuery = graphql`
  query WebMobileAppDevelopmentPageTemplate {
    recentWork: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "CaseStudy/index" } } }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            heroLogo {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardImage {
              src {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            title
          }
        }
      }
    }
    blogs: allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Development" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
    file(relativePath: { 
      eq: "mobile-app@2x.png" 
    }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero: file(relativePath: {
      eq: "software-development-team.jpg"
    }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
