import React from 'react';

import './index.css';

export default () => {
  return (
    <div className="items">
      <ul>
        <li>
          <p>React</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>.NET</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Vue</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>React Native</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Angular</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Flutter</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Node</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Native iOS</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Python</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Android</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Ruby on Rails</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>C++</p>
        </li>
      </ul>
    </div>
  );
};
