import React from 'react';

import './index.css';

const HeroBody = () => {
  return (
    <section className="web-mobile-app-development-hero-body-container">
      <p>
        No matter who your target user is, they are using multiple applications 
        in their professional and personal lives. These users have an insatiable 
        demand for innovation and are always looking for the next best feature 
        and user experience. To keep up with demand, you’ve got to have a clear 
        roadmap and the manpower to execute both short and long-term application 
        updates. 
        <br />
        <br />
        We can help you leverage the latest technology to create communication, 
        collaboration, productivity, and entertainment applications that provide 
        tangible value to your users. 
      </p>
    </section>
  );
};

export default HeroBody;
