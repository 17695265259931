import React from 'react';

import Img from 'gatsby-image';

import './index.css';

export default ({item}) => {
  return (
    <section className="web-mobile-app-development-custom">
      <div className="secondary-padding">
        <div className="blog-left left">
          <p className="c-title">Capabilities & Possibilities</p>
          <p className="subtitle">
            To build modern applications, Devety leverages data aggregation 
            and science, AR/VR, AI and machine learning, and other technologies 
            to bring the latest features in app development. We can help your 
            application with chatbots, single sign-on, wearable technology, 
            security, mobile wallets, and more. 
          </p>
          <p className="c-title">Why Devetry</p>
          <p className="subtitle">
            Before a line of code is written, Devetry digs deep to understand 
            your application’s users. That’s when the best apps are created. 
            Are your users accessing your app in a remote setting? How tech-savvy 
            are they? Will they use it on a desktop, mobile device, or both? 
            Taking these essential considerations into account allows us to 
            strategize a minimum viable product or enterprise application that 
            accomplishes your business goals. 
          </p>
        </div>
      </div>
      <div className="right img-container">
        <Img className='mobile-app-img'
          fluid={item.childImageSharp.fluid}
          alt="mobile app"
        />

      </div>

    </section>
  );
};
